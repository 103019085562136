import React from 'react';
import { SimpleGrid, Box } from '@chakra-ui/react';
import ServiceCard from './ServiceCard';

const ServiceGrid = () => {
    const servicesData = [
        {
            title: 'Live Chat Support',
            imageSrc: '/Images/VA2.jpeg', 
            services: [
                'Customer retention',
                'Reduce Churn',
                'User loyalty',
                'Detailed weekly analysis',
                '24/7 support availability',
            ],
        },
        {
            title: 'Email Support',
            imageSrc: '/Images/DM.jpg', 
            services: [
                'Quality Assurance',
                '80%+ CSAT',
                'Customer retention',
                'Canned response optimization',
                '24/7 support availability',
            ],
        },
        {
            title: 'Social Media Support',
            imageSrc: '/Images/IT.jpeg', 
            services: [
                'All Social Media Channels',
                'Creating Brand Loyalty',
                'Up to 23% Upsell',
                'User Loyalty',
                'Under 120 Seconds Response Time',
            ],
        },
    ];

    return (
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} mt={8} justifyContent={'center'}>
            {servicesData.map((service, index) => (
                <Box key={index} display="flex" justifyContent="center">

                    <ServiceCard
                        key={index}
                        title={service.title}
                        imageSrc={service.imageSrc}
                        services={service.services}
                    />
                </Box>
            ))}
        </SimpleGrid>
    );
};

export default ServiceGrid;
