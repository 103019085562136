import { Box, Grid, Heading, Image, Text } from '@chakra-ui/react';

export default function TextContent() {
    return (
        <Box p={10} maxW="1200px" mx="auto">
            <Grid templateColumns={{ base: "1fr", md: "1fr" }} ml={{base: 0 , md:20}} gap={8} alignItems="center">
                <Box>
                    <Heading
                        lineHeight={1.2}
                        fontWeight={600}
                        fontSize={{ base: '5xl', sm: '6xl', lg: '10xl' }}
                    >
                    Industries We Excel In
                    </Heading>

                    <Text fontSize="xl" fontWeight="medium" color="gray.700" mt={6}>
                         Ecommerce <br />
                         SaaS <br />
                        Coaching Businesses
                    </Text>
                </Box>
            </Grid>
        </Box>
    );
}
