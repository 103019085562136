export const servicesData = [
    {
        category: "Live Chat Support",
        title: "Hire Live Chat Support",
        image: "/Images/service/chat.svg",
        services: [
            { heading: "Customer retention", description: "Provided agents help to retain users and develop connections to boost brand loyalty, user retention, and positive experience." },
            { heading: "Reduced Churn", description: "The provided agents are fully trained to develop empathy and resolve user problems to reduce churn and create a positive experience." },
            { heading: "Up to 23% Upsell", description: "The provided support by Aquilis can boost sales up to 23% with empathy and a positive experience." },
            { heading: "Under 90 seconds response time", description: "At Aquilis, the response time for any query in chat is under 90 seconds for agents." },
            { heading: "Detailed weekly Analytics", description: "The performance of the team will be shared on a weekly basis to provide insights about the ongoing project." },
            { heading: "80%+ CSAT", description: "Agents at Aquilis are trained thoroughly to always have 80%+ CSAT scores, and these reports are shared on a weekly basis." },
            { heading: "Quality Assurance", description: "Every week, the quality is marked for agents to maintain workflow and follow guidelines accordingly." },
            { heading: "Team Lead", description: "Every team is given a team lead to push agents towards excellence and unlock their unique abilities to handle customer queries." },
            { heading: "User loyalty", description: "Agents receive special training to develop empathy and refine their tone to ensure the best customer experience." },
            { heading: "Flexible and scalable teams", description: "Teams are readily available to get trained and provide support to customers with ease." },
            { heading: "24/7 support availability", description: "Aquilis teams are available at any given time to handle customer queries." }
        ]
    },
    {
        category: "Email Support",
        title: "Hire Email Support",
        image: "/Images/service/email.svg",
        services: [
            { heading: "Customer retention", description: "Provided agents help to retain users and develop connections to boost brand loyalty, user retention, and positive experience." },
            { heading: "Reduce Churn", description: "The given agents are fully trained to develop empathy and resolve user problems to reduce churn and create a positive experience." },
            { heading: "User loyalty", description: "Agents receive special training to develop empathy and refine their tone to ensure the best customer experience." },
            { heading: "80%+ CSAT", description: "Agents at Aquilis are trained to always achieve 80%+ CSAT scores, and these reports are shared weekly." },
            { heading: "Quality Assurance", description: "Every week, quality is marked to maintain workflow and follow guidelines accordingly." },
            { heading: "Team Lead", description: "Every team is given a team lead to push agents towards excellence and unlock their unique abilities to handle customer queries." },
            { heading: "Canned response optimization", description: "Teams are equipped with pre-written personalized email templates to reduce response time while maintaining quality." },
            { heading: "Detailed weekly analysis", description: "The team’s performance is shared weekly to provide insights about ongoing projects." },
            { heading: "24/7 support availability", description: "Aquilis teams are available 24/7 to handle customer queries." }
        ]
    },
    {
        category: "Voice Support",
        title: "Hire Voice Support",
        image: "/Images/service/voice.svg",
        services: [
            { heading: "Customer Retention", description: "Provided agents help retain users and develop connections to boost brand loyalty, user retention, and positive experience." },
            { heading: "Accent Neutrality", description: "Team members are trained to communicate effectively with a neutral accent suitable for global customers." },
            { heading: "Reduce Churn", description: "The given agents are fully trained to develop empathy and resolve user problems to reduce churn and create a positive experience." },
            { heading: "Up to 23% Upsell", description: "The provided support by Aquilis can boost sales up to 23% with empathy and a positive experience." },
            { heading: "User Loyalty", description: "Agents help retain users and develop connections to boost brand loyalty, user retention, and positive experience." },
            { heading: "80%+ CSAT", description: "Agents at Aquilis are trained thoroughly to always have 80%+ CSAT scores, and these reports are shared weekly." },
            { heading: "Quality Assurance", description: "Every week, quality is marked to maintain workflow and follow guidelines accordingly." },
            { heading: "Team Lead", description: "Every team is assigned a lead to ensure excellence and help agents handle customer queries effectively." }
        ]
    },
    {
        category: "Social Media Support",
        title: "Hire Social Media Support",
        image: "/Images/service/social.svg",
        services: [
            { heading: "All Social Media Channels", description: "Team Aquilis is equipped to handle customer queries across Instagram, Facebook, Twitter, WhatsApp, Discord, and Reddit." },
            { heading: "Creating Brand Loyalty", description: "Provided agents help retain users and develop connections to boost brand loyalty, user retention, and positive experience." },
            { heading: "Under 120 Seconds Response Time", description: "At Aquilis, the response time for any query on social media is under 120 seconds." },
            { heading: "Reduce Churn", description: "The given agents are fully trained to develop empathy and resolve user problems to reduce churn and create a positive experience." },
            { heading: "Up to 23% Upsell", description: "The provided support by Aquilis can boost sales up to 23% with empathy and a positive experience." },
            { heading: "User Loyalty", description: "Agents help retain users and develop connections to boost brand loyalty, user retention, and positive experiences." },
            { heading: "Quality Assurance", description: "Every week, the quality is assessed to maintain workflow and follow guidelines accordingly." },
            { heading: "Team Lead", description: "Every team is assigned a lead to ensure excellence and help agents handle customer queries effectively." }
        ]
    },
    {
        category: "Technical Support",
        title: "Hire Technical Support",
        image: "/Images/service/technical.svg",
        services: [
            { heading: "Integrating Third-Party Apps", description: "The provided agents are trained to integrate third-party apps into given platforms." },
            { heading: "Setting Up Store", description: "Agents are trained to provide end-to-end assistance for setting up online stores." },
            { heading: "Troubleshooting Technical Issues", description: "Agents must pass knowledge base tests for software, hardware, and systems." },
            { heading: "Document Interactions", description: "Agents are fully trained to handle and record document-related interactions." },
            { heading: "80%+ CSAT Score", description: "Agents at Aquilis consistently achieve 80%+ CSAT scores, with reports shared weekly." },
            { heading: "24/7 Support Assistance", description: "Aquilis teams are available 24/7 to handle technical queries." },
            { heading: "Multi-Tier Support", description: "The team provides layered support from L1-L3, handling both base-level and advanced technical problems." }
        ]
    },
    {
        category: "Back Office",
        title: "Hire Back Office Support",
        image: "/Images/service/back.svg",
        services: [
            { heading: "Data Verification", description: "The team ensures data integrity and process verification." },
            { heading: "Data Processing", description: "Agents handle raw data and compile it for streamlined operations." },
            { heading: "Solving Technical Issues", description: "Back-office teams troubleshoot backend issues for smooth operations." },
            { heading: "Assistance in Approval", description: "Agents follow approved workflows to streamline processes." },
            { heading: "Process Invoices", description: "Agents are trained to process payments, validate transactions, and generate invoices." },
            { heading: "Handling T&C", description: "Back-office representatives are trained to manage tasks in compliance with terms and conditions." }
        ]
    }
];

