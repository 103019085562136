'use client';

import React from 'react';
import {
    Box,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Heading,
    Text,
    Image,
    VStack,
    useBreakpointValue,
} from '@chakra-ui/react';
import { servicesData } from './serviceData';

const ServiceTabs = () => {
    // Dynamically adjust tab layout based on screen size
    const tabSize = useBreakpointValue({ base: "sm", md: "md", lg: "lg" });

    return (
        <Box px={{ base: 4, md: 10 }}>
            <Heading textAlign="center" my={8} color="red.700" fontSize={{ base: '2xl', md: '3xl' }}>
                Agents You Can Hire
            </Heading>

            <Tabs variant="line" colorScheme="red" isFitted>
            <TabList 
                overflowX="auto" 
                whiteSpace="nowrap" 
                flexWrap="nowrap"  
                css={{
                    scrollbarWidth: "none",  
                    "-ms-overflow-style": "none" 
                }}
            >
                {servicesData.map((service, index) => (
                    <Tab 
                        key={index} 
                        fontSize={tabSize} 
                        _selected={{ color: 'red.700', borderColor: 'red.700' }}
                        flexShrink={0} 
                    >
                        {service.category}
                    </Tab>
                ))}
            </TabList>


                <TabPanels>
                    {servicesData.map((service, index) => (
                        <TabPanel key={index}>
                            <VStack spacing={6} mt={4}>
                                {/* Responsive Image */}
                                <Image
                                    src={service.image}
                                    alt={service.category}
                                    rounded="2xl"
                                    boxShadow="2xl"
                                    objectFit="cover"
                                    width={{ base: "100%", md: "500px" }} // Responsive width
                                    height={{ base: "200px", md: "250px" }} // Responsive height
                                    border="2px solid"
                                    borderColor="gray.200"
                                    borderRadius="md"
                                />

                                {/* Title */}
                                <Text
                                    fontWeight="bold"
                                    fontSize={{ base: "lg", md: "xl" }}
                                    color="red.800"
                                    textAlign="center"
                                    p={4}
                                    maxW="600px"
                                >
                                    {service.title}
                                </Text>

                                {/* Services List */}
                                <VStack
                                    spacing={4}
                                    alignItems="flex-start"
                                    p={4}
                                    border="1px solid"
                                    borderColor="gray.400"
                                    borderRadius="lg"
                                    maxW={{ base: "100%", md: "80%" }}
                                    width="100%"
                                    bg="red.50"
                                >
                                    {service.services.map((serviceDetail, i) => (
                                        <Box key={i} width="100%">
                                            <Heading size="md" color="red.700" mb={2}>
                                                {serviceDetail.heading}
                                            </Heading>
                                            <Text>
                                                {serviceDetail.description}
                                            </Text>
                                        </Box>
                                    ))}
                                </VStack>
                            </VStack>
                        </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
        </Box>
    );
};

export default ServiceTabs;
