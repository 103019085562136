import React from 'react';
import { Box, Heading, SimpleGrid, Text, Flex } from '@chakra-ui/react';
import FeatureItem from './FeatureItem';

const FeaturesSection = () => {
    const features = [
        {
            title: 'Fast and Flexible Scalability',
            description: 'The system is designed to move with speed and scale your customer support team faster. Our trained team helps to achieve more while maintaining data security.',
        },
        {
            title: 'Stay Focused on What Matters',
            description: 'Your main focus is to grow your business and not to get side tracked while managing customer support. Let us handle support so you can keep the focus where it matters the most.',
        },
        {
            title: 'Trained and Skilled Employees:',
            description: 'Access a pool of highly skilled , dedicated professionals in customer support with a proven track record that are ready to drive your business forward.',
        },
    ];

    return (
        <Box textAlign="center" py={10} position="relative">
            <Heading as="h2" fontSize="2xl" color="red.700" mb={4}>
                What We Do
            </Heading>
            <Text
                mx="auto" // Centers the text horizontally
                width={{ base: '100%', md: '70%', lg: '60%' }} // Constrains the width on larger screens
                mb={8}

            >
                We are Aquilis, we provide top outsourced talented individuals tailored for the start ups and scales ups that helps to grow and streamline your customer support for business to remove any time consuming task and helps you to focus on what really matters the most, the growth and satisfaction. 
            </Text>

            {/* Line above features */}
            <Box position="relative" mb={8}>
                <Box
                    position="absolute"
                    top="0"
                    left="0"
                    right="0"
                    borderTop="2px solid black"
                    width="100%"
                    zIndex={1}
                />

                {/* Black Dots */}
                <Flex justify="space-between" position="relative" zIndex={2} top="-5px">
                    {features.map((_, index) => (
                        <Box key={index} position="relative" width="33.33%">
                            <Box
                                as="span"
                                display="block"
                                width="12px"
                                height="12px"
                                bg="black"
                                borderRadius="50%"
                                mx="auto"
                                position="absolute"
                                left="50%"
                                transform="translateX(-50%)" // This will center the dot
                            />
                        </Box>
                    ))}
                </Flex>



            </Box>

            {/* Features List */}
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
                {features.map((feature, index) => (
                    <FeatureItem key={index} title={feature.title} description={feature.description} />
                ))}
            </SimpleGrid>
        </Box>
    );
};

export default FeaturesSection;
