'use client';

import { Flex } from '@chakra-ui/react';

export default function AboutHero() {
    return (
        <Flex
            w={'full'}
            h={{ base: '30vh', md: '70vh' }}
            backgroundImage={'/Images/About.png'}
            backgroundSize={'cover'}
            backgroundPosition={'center'}
            backgroundRepeat="no-repeat"
        />
    );
}
